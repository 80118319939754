<script>
  export let title;
</script>

<style>

</style>

<div class="tile is-child box">
  <p class="subtitle">{title}</p>
  <hr />
  <slot />
</div>
